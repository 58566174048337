import React, { memo, useState } from 'react';
import { Header } from 'common/Header';
import {
    Heading,
    Box,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
} from '@chakra-ui/react';
import { QuestionList } from './components/QuestionsList';
import { initialSettings, settingsType } from './types';
import { SettingsInput } from './components/SettingsInput';
import {
    crtHundredCellQuestions,
    generateKnockQuestions,
} from 'utils/functions/randomGenerator';
import { AnswerSheet } from './components/AnswerSheet';
import { HundredCells } from './components/HundredCells';
// import { RefreshButton } from 'common/components/RefreshButton';

export const Home = memo(() => {
    const [settings, setSettings] = useState<settingsType>(initialSettings);
    // const [count, setCount] = useState<number>(0);
    const today = new Date();
    const hiduke = `${today.getMonth() + 1}月${today.getDate()}日`;
    const questions =
        settings.questionAmount !== 100
            ? generateKnockQuestions(settings)
            : crtHundredCellQuestions(settings);
    console.log(questions);

    return (
        <>
            <Header />
            <Box m={'16px'}>
                <Heading as="h3" size="lg">
                    {hiduke}の計算練習
                </Heading>

                <SettingsInput settings={settings} setSettings={setSettings} />

                {/* <Box ml={'24px'}>
                    <RefreshButton onClickFunc={() => setCount(count + 1)} />
                </Box> */}
                <Box>
                    <Tabs variant="enclosed">
                        <TabList>
                            <Tab>印刷用</Tab>
                            <Tab>解答</Tab>
                            <Tab>タイムアタック</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {settings.questionAmount !== 100 && (
                                    <QuestionList questions={questions} />
                                )}
                                {settings.questionAmount === 100 && (
                                    <HundredCells
                                        questions={questions}
                                        isAnswer={false}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {settings.questionAmount !== 100 && (
                                    <AnswerSheet questions={questions} />
                                )}
                                {settings.questionAmount === 100 && (
                                    <HundredCells
                                        questions={questions}
                                        isAnswer={true}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                <Box>これから作ります</Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Box>
        </>
    );
});
