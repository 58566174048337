export const KomachiSolver = (
    target: number,
    list: (number | undefined | 'なし')[]
) => {
    const numList = [];
    for (let num of list) {
        if (num === 'なし' || num === undefined) {
            continue;
        }
        numList.push(Number(num));
    }
    return checkIfSolvable(target, numList);
};

const enzan = ['÷', '-', '+', '×'];

export const checkIfSolvable = (target: number, list: number[]) => {
    console.log('input=', list);
    if (list.length < 2) {
        return false;
    }
    if (list.length === 2) {
        return isTargetExistInList(target, list);
    }
    if (list.length === 3) {
        return solverThree(target, list);
    }
    if (list.length === 4) {
        return solve(target, list);
    }
    return list.length > 1;
};

// 一旦 n=3とする
const solverThree = (target: number, list: number[]) => {
    let eq = '';
    if (list.length !== 3) {
        throw new Error('n=3にだけ使ってください');
    }
    const possibilities = makePermutation(list);
    for (const p of possibilities) {
        let goals = [target * p[0], target + p[0], target - p[0]];
        if (p[0] !== 0) {
            goals.push(target / p[0]);
        }
        // 和差商積繰り返す
        for (let i = 0; i < goals.length; i++) {
            if (!!isTargetExistInList(goals[i], p.slice(1))) {
                eq = `(${isTargetExistInList(goals[i], p.slice(1))})${
                    enzan[i]
                }${p[0]}`;
                return eq;
            }
            continue;
        }
        continue;
    }
    return false;
};

// 一旦 n=4とする
const solve = (target: number, list: number[]) => {
    let eq = '';
    if (list.length !== 4) {
        throw new Error('n=4にだけ使ってください');
    }
    const possibilities = makePermutation(list);
    for (const p of possibilities) {
        let goals = [target * p[0], target + p[0], target - p[0]];
        if (p[0] !== 0) {
            goals.push(target / p[0]);
        }
        for (let i = 0; i < goals.length; i++) {
            if (!!solverThree(goals[i], p.slice(1))) {
                eq = `(${solverThree(goals[i], p.slice(1))})${enzan[i]}${p[0]}`;
                return eq;
            }
            continue;
        }
        continue;
    }
    return false;
};

// 色々計算した結果がリストにあるかを返す, N==2で使う。
const isTargetExistInList = (target: number, list: number[]) => {
    let eq = '';
    if (list.length === 2) {
        let goals = [target * list[0], target + list[0], target - list[0]];

        if (list[0] !== 0) {
            goals.push(target / list[0]);
        }
        goals.forEach((g, idx) => {
            if (isSameNum(g, list[1])) {
                eq = `${list[1]}${enzan[idx]}${list[0]}`;
            }
        });
        return eq;
    }
    throw new Error('この関数はlengthが2のリストにのみ使ってください');
};

// 数字がイコール化を判定する
const isSameNum = (a: number, b: number) => {
    return Math.abs(a - b) < 0.001;
};

// n>2の利用を想定, 全て使う
type permutation = (nums: number[]) => number[][];
const makePermutation: permutation = (nums) => {
    const k = nums.length;
    let ans = [];
    if (nums.length < k) {
        return [];
    }
    if (k === 1) {
        for (let i = 0; i < nums.length; i++) {
            ans[i] = [nums[i]];
        }
    } else {
        for (let i = 0; i < nums.length; i++) {
            let parts = nums.slice(0);
            //eslint-disable-next-line
            const _ = parts.splice(i, 1)[0];
            let row = makePermutation(parts);
            for (let j = 0; j < row.length; j++) {
                ans.push([nums[i]].concat(row[j]));
            }
        }
    }
    return ans;
};
