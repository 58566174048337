import React, { memo, FC } from 'react';

import { Text, Box, Flex } from '@chakra-ui/react';

type Props = {
    examples: number[][];
};

export const PracticePage: FC<Props> = memo(({ examples }) => {
    return (
        <Box ml={'-4px'}>
            {examples.map((qList, i) => {
                const firstNum = qList[0];
                const secondNum = qList[1];
                return (
                    <Flex key={i}>
                        <Box w={'50px'}>
                            <Text fontSize="2xl" mb={'20px'}>
                                {`(${i + 1})`}
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="2xl" mb={'20px'}>
                                {String(firstNum)} × {String(secondNum)} =
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="2xl" ml={'20px'}>
                                ________
                            </Text>
                        </Box>
                    </Flex>
                );
            })}
        </Box>
    );
});
