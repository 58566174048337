import React, { memo, FC, Dispatch, SetStateAction, useState } from 'react';
import {
    Text,
    Box,
    Flex,
    Select,
    Slider,
    SliderThumb,
    Tooltip,
    SliderTrack,
    SliderFilledTrack,
} from '@chakra-ui/react';
import { settingsType } from '../types';

type Props = {
    settings: settingsType;
    setSettings: Dispatch<SetStateAction<settingsType>>;
};
export const SettingsInput: FC<Props> = memo(({ settings, setSettings }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <>
            <Flex mt={'16px'} mb={'16px'}>
                <Box w={'100px'}>
                    <Select
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                firstDigit: Number(e.target.value),
                            })
                        }
                        defaultValue={2}
                    >
                        <option value={1}>1桁</option>
                        <option value={2}>2桁</option>
                        <option value={3}>3桁</option>
                    </Select>
                </Box>
                <Text fontSize="2xl" ml={'10px'}>
                    ×
                </Text>
                <Box w={'100px'} ml={'10px'}>
                    <Select
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                secondDigit: Number(e.target.value),
                            })
                        }
                    >
                        <option value={1}>1桁</option>
                        <option value={2}>2桁</option>
                        <option value={3}>3桁</option>
                    </Select>
                </Box>
                <Text fontSize="2xl" ml={'10px'}>
                    の「かけ算」
                </Text>

                <Text fontSize="2xl" ml={'10px'}>
                    を
                </Text>
                <Box w={'100px'} ml={'10px'}>
                    <Select
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                questionAmount: Number(e.target.value),
                            })
                        }
                    >
                        <option value={10}>10問</option>
                        <option value={20}>20問</option>
                        <option value={30}>30問</option>
                        <option value={100}>100問</option>
                    </Select>
                </Box>
            </Flex>

            <Flex mt={'24px'} mb={'32px'} width={'60vw'}>
                <Box width={'10vw'}>
                    <Text fontSize="sm" ml={'10px'}>
                        難易度
                    </Text>
                </Box>

                <Slider
                    id="slider"
                    onChange={(val) =>
                        setSettings({
                            ...settings,
                            difficulty: Number(val),
                        })
                    }
                    min={0}
                    max={8}
                    colorScheme="teal"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    {/* <SliderMark value={1} mt="1" ml="-2.5" fontSize="sm">
                        やさしい
                    </SliderMark>
                    <SliderMark value={3} mt="1" ml="-2.5" fontSize="sm">
                        ふつう
                    </SliderMark>
                    <SliderMark value={5} mt="1" ml="-2.5" fontSize="sm">
                        難しい
                    </SliderMark>
                    <SliderMark value={7} mt="1" ml="-2.5" fontSize="sm">
                        天才
                    </SliderMark> */}

                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <Tooltip
                        hasArrow
                        bg="teal.500"
                        color="white"
                        placement="top"
                        isOpen={showTooltip}
                        label={convertDifficulty(settings.difficulty)}
                    >
                        <SliderThumb />
                    </Tooltip>
                </Slider>
            </Flex>
        </>
    );
});

const convertDifficulty = (difficulty: number) => {
    if (difficulty < 3) {
        return 'やさしい';
    }
    if (difficulty < 5) {
        return 'ふつう';
    }
    if (difficulty < 7) {
        return 'むずかしい';
    }

    return '天才レベル';
};
