import React, { memo, FC } from 'react';

import { Text, Box, Flex } from '@chakra-ui/react';

type Props = {
    examples: number[][];
};

export const AnswerPage: FC<Props> = memo(({ examples }) => {
    return (
        <Box ml={'-4px'}>
            {examples.map((qList, i) => {
                const firstNum = qList[0];
                const secondNum = qList[1];
                const center = Math.floor((qList[0] + qList[1]) / 2);
                const delta = Math.floor(firstNum - center);
                return (
                    <Flex key={i} mb={'12px'}>
                        <Box w={'50px'}>
                            <Text fontSize="2xl" mb={'20px'}>
                                {`(${i + 1})`}
                            </Text>
                        </Box>
                        <Box w={'90px'}>
                            <Text fontSize="2xl" mb={'20px'}>
                                {String(firstNum)} × {String(secondNum)}
                            </Text>
                        </Box>
                        <Box>
                            <Box>
                                <Text
                                    fontSize="2xl"
                                    ml={'6px'}
                                >{`= (${center} + ${delta}) × (${center} - ${delta})`}</Text>
                            </Box>
                            <Box>
                                <Text
                                    fontSize="2xl"
                                    ml={'6px'}
                                >{`= (${center} × ${center}) - (${delta} × ${delta})`}</Text>
                            </Box>
                            <Box>
                                <Text fontSize="2xl" ml={'6px'}>{`= ${
                                    center * center
                                } - ${delta * delta}`}</Text>
                            </Box>
                            <Box>
                                <Text fontSize="2xl" ml={'6px'}>{`= ${
                                    center * center - delta * delta
                                }`}</Text>
                            </Box>
                        </Box>
                    </Flex>
                );
            })}
        </Box>
    );
});
