import React, { memo, FC } from 'react';
import { Box } from '@chakra-ui/react';
import classes from './hundredCells.module.css';

type Props = {
    questions: number[][];
    isAnswer: boolean;
};

export const HundredCells: FC<Props> = memo(({ questions, isAnswer }) => {
    return (
        <Box>
            <table>
                <tr>
                    <th className={classes.header}> × </th>
                    {questions[0].map((num) => {
                        return <th className={classes.header}>{num}</th>;
                    })}
                </tr>
                {questions[1].map((num, i) => {
                    return (
                        <tr>
                            <td className={classes.cell}>{num}</td>
                            {[...Array(10)].map((_, idx) => (
                                <td className={classes.blank}>
                                    {isAnswer &&
                                        String(num * questions[0][idx])}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </table>
        </Box>
    );
});
