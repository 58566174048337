import React from 'react';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/HomePage';
import { FindCenter } from 'pages/Technique/FindCenter';
import { Komachi } from 'pages/Suukan/Komachi';

function App() {
    return (
        <ChakraProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={`/`} element={<Home />} />
                    <Route
                        path={`/technique/center`}
                        element={<FindCenter />}
                    />
                    <Route path={`/komachi`} element={<Komachi />} />
                </Routes>
            </BrowserRouter>
        </ChakraProvider>
    );
}

export default App;
