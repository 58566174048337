import React, { memo, useState } from 'react';
import { Header } from 'common/Header';
import { PracticePage } from './components/PracticePage';
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Heading,
    Box,
    Flex,
    Button,
} from '@chakra-ui/react';
import { ExplanationPage } from './components/ExplanationPage';
import { generateExample } from 'utils/functions/randomGenerator';
import { AnswerPage } from './components/AnswerPage';
import { IoRefreshSharp } from 'react-icons/io5';

export const FindCenter = memo(() => {
    const [count, setCount] = useState<number>(0);
    const examples = generateExample('findCenter', 10);

    return (
        <>
            <Header />
            <Box m={'16px'}>
                <Flex>
                    {' '}
                    <Heading as="h3" size="lg">
                        まん中の数をさがせ！
                    </Heading>
                    <Box ml={'24px'}>
                        <Button
                            rightIcon={<IoRefreshSharp />}
                            colorScheme="blue"
                            variant="outline"
                            size="sm"
                            onClick={() => setCount(count + 1)}
                        >
                            問題リセット
                        </Button>
                    </Box>
                </Flex>
            </Box>
            <Box ml={'16px'}>
                <Tabs variant="enclosed">
                    <TabList>
                        <Tab>とりあえずの10問！</Tab>
                        <Tab>解答</Tab>
                        <Tab>タネ明かし</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <PracticePage examples={examples} />
                        </TabPanel>
                        <TabPanel>
                            <AnswerPage examples={examples} />
                        </TabPanel>
                        <TabPanel>
                            <ExplanationPage />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
    );
});
