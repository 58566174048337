import React, { memo, FC } from 'react';

import { Text, Box, Flex } from '@chakra-ui/react';

// https://chakra-ui.com/docs/styled-system/style-props これ見て装飾するとやばい!メッチャ良い！

type Props = {
    questions: (number | undefined)[][];
};

export const QuestionList: FC<Props> = memo(({ questions }) => {
    return (
        <Box>
            {questions.map((question, i) => {
                return (
                    <Flex key={i}>
                        <Box w={'50px'}>
                            <Text fontSize="2xl" mb={'20px'}>
                                {`(${i + 1})`}
                            </Text>
                        </Box>
                        <Box maxWidth={'150px'}>
                            <Text fontSize="2xl" mb={'20px'}>
                                {String(question[0])} × {String(question[1])}
                            </Text>
                        </Box>
                        <Box w={'20px'} ml={'10px'}>
                            <Text fontSize="2xl" mb={'20px'}>
                                =
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="2xl" ml={'10px'}>
                                ________
                            </Text>
                        </Box>
                    </Flex>
                );
            })}
        </Box>
    );
});
