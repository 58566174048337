export type operationType =
    | 'addition'
    | 'subtraction'
    | 'multipulation'
    | 'division';

export type settingsKeys =
    | 'questionAmount'
    | 'firstDigit'
    | 'secondDigit'
    | 'operation';

export type settingsType = {
    questionAmount: number;
    firstDigit: number;
    secondDigit: number;
    operation: operationType;
    difficulty: number;
};

export const initialSettings = {
    questionAmount: 10,
    firstDigit: 2,
    secondDigit: 1,
    operation: 'multipulation' as operationType,
    difficulty: 4,
};
