import React, { memo, FC, ChangeEvent } from 'react';
import { Select, Box } from '@chakra-ui/react';

type Props = {
    handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

export const CustomNumberInput: FC<Props> = memo(({ handleChange }) => {
    return (
        <Box width={'80px'}>
            <Select onChange={handleChange} defaultValue={2}>
                <option value={undefined}>なし</option>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
            </Select>
        </Box>
    );
});
