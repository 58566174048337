import React, { memo } from 'react';
import {
    Heading,
    Box,
    Flex,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerContent,
    // Input,
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { DrawerOptions } from './DrawerOptions';

export const Header = memo(() => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    // const btnRef = React.useRef();

    return (
        <>
            <Box backgroundColor={'#00bfff'} padding={'12px'}>
                <Flex>
                    <Box width={'40px'}>
                        <IconButton
                            variant="outline"
                            aria-label="Call Sage"
                            fontSize="20px"
                            icon={<HamburgerIcon color={'white'} />}
                            onClick={onOpen}
                        />
                    </Box>

                    <Heading as="h3" size="lg" color={'white'} ml={'32px'}>
                        Mathemagic
                    </Heading>
                </Flex>
            </Box>

            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                // finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>何を学びたい？</DrawerHeader>

                    <DrawerBody>
                        {/* <Input placeholder="Type here..." /> */}

                        <DrawerOptions />
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            キャンセル
                        </Button>
                        {/* <Button colorScheme="blue">Save</Button> */}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
});
