import { Header } from 'common/Header';
import React, { memo, FC, useState } from 'react';
import {
    Text,
    Box,
    Flex,
    Heading,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import { CustomNumberInput } from './components/CustomNumberInput';
import { KomachiSolver } from './functions/komachi_solver';
import { numberListType, keyType } from './types';

export const Komachi: FC = memo(() => {
    const [numberList, setNumberList] = useState<numberListType>({
        first: 2,
        second: 2,
        third: 2,
        fourth: 2,
    });
    const hasSolution = KomachiSolver(10, Object.values(numberList));
    console.log(numberList, hasSolution);

    const handleChange =
        (key: keyType) => (e: React.ChangeEvent<HTMLSelectElement>) => {
            console.log(e.target.value);
            setNumberList({
                ...numberList,
                [key]: e.target.value,
            });
        };

    return (
        <>
            <Header />
            <Box margin={'16px'}>
                <Heading as="h3" size="lg">
                    こまち算
                </Heading>
                <Box mt={'16px'}>
                    <Tabs variant="enclosed">
                        <TabList>
                            <Tab>求解AI</Tab>
                            <Tab>タネ明かし</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Box></Box>
                                <Flex>
                                    <CustomNumberInput
                                        handleChange={handleChange('first')}
                                    />
                                    <CustomNumberInput
                                        handleChange={handleChange('second')}
                                    />
                                    <CustomNumberInput
                                        handleChange={handleChange('third')}
                                    />
                                    <CustomNumberInput
                                        handleChange={handleChange('fourth')}
                                    />
                                </Flex>

                                <Box mt={'32px'}>
                                    <Heading fontSize="lg">
                                        解答例:
                                        {(!!hasSolution &&
                                            String(hasSolution)) ||
                                            '解なしです'}
                                    </Heading>
                                </Box>
                            </TabPanel>
                            <TabPanel>後で作ります</TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Box>
        </>
    );
});
