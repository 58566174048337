import { settingsType } from 'pages/HomePage/types';

export const randomGenerator = (min: number, max: number) =>
    Math.floor(Math.random() * (max + 1 - min)) + min;

export const hardGenerator = (min: number, max: number) => {
    let a = Math.floor(Math.random() * (max + 1 - min)) + min;
    if (a % 5 === 0) {
        return a + randomGenerator(1, 4);
    }
    return a;
};

export const generatorWithDifficulty = (digit: number, difficulty: string) => {
    if (difficulty === 'easy') {
        if (digit === 1) {
            return randomGenerator(2, 5);
        }
        if (digit === 2) {
            return randomGenerator(11, 31);
        }
        if (digit === 3) {
            return randomGenerator(101, 200);
        }
    }
    if (difficulty === 'middle') {
        if (digit === 1) {
            return randomGenerator(2, 7);
        }
        if (digit === 2) {
            return randomGenerator(11, 60);
        }
        if (digit === 3) {
            return randomGenerator(101, 200);
        }
    }
    if (difficulty === 'hard') {
        if (digit === 1) {
            return randomGenerator(2, 9);
        }
        if (digit === 2) {
            return randomGenerator(11, 99);
        }
        if (digit === 3) {
            return randomGenerator(101, 999);
        }
    }
    // 一番難しいやつ

    if (digit === 1) {
        return hardGenerator(6, 9);
    }
    if (digit === 2) {
        return hardGenerator(61, 99);
    }
    if (digit === 3) {
        return hardGenerator(401, 999);
    }
    return 1;
};

type exampleType = 'findCenter' | 'fiveDouble' | 'knock';

export const generateExample = (exampleType: exampleType, amount: number) => {
    let arr: number[][] = [];
    if (exampleType === 'findCenter') {
        for (let step = 0; step < amount; step++) {
            const centerNum = randomGenerator(1, 9) * 10;
            const delta = randomGenerator(1, 5);
            arr.push([centerNum + delta, centerNum - delta]);
        }
        return arr;
    }
    return arr;
};

export const generateKnockQuestions = (settings: settingsType) => {
    let arr = [];
    for (let step = 0; step < settings.questionAmount; step++) {
        const firstNum = generatorWithDifficulty(
            settings.firstDigit,
            convertDifficulty(settings.difficulty)
        );
        const secondNum = generatorWithDifficulty(
            settings.secondDigit,
            convertDifficulty(settings.difficulty)
        );
        arr.push([firstNum, secondNum]);
    }
    return arr;
};

export const convertDifficulty = (difficulty: number) => {
    if (difficulty < 3) {
        return 'easy';
    }
    if (difficulty < 5) {
        return 'middle';
    }
    if (difficulty < 7) {
        return 'hard';
    }
    return '天才レベル';
};

// FIXME: 一つ前と同じ数字が出ないようにする
export const crtForHundredCells = (digit: number, difficulty: number) => {
    let arr: number[] = [];
    for (let step = 0; step < 10; step++) {
        let nextNum = generatorWithDifficulty(
            digit,
            convertDifficulty(difficulty)
        );
        if (step > 0 && nextNum === arr[step - 1]) {
            nextNum = nextNum % 10 > 5 ? nextNum - 2 : nextNum + 2;
        }
        arr.push(nextNum);
    }
    return arr;
};

export const crtHundredCellQuestions = (settings: settingsType) => {
    const firstDigitList = crtForHundredCells(
        settings.firstDigit,
        settings.difficulty
    );
    const secondDigitList = crtForHundredCells(
        settings.secondDigit,
        settings.difficulty
    );
    return [firstDigitList, secondDigitList];
};
