import React, { memo, FC } from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ListItem, OrderedList } from '@chakra-ui/react';

export const DrawerOptions: FC = memo(() => {
    return (
        <>
            {/* <Accordion defaultIndex={[0]} allowMultiple> */}
            <Accordion allowMultiple>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                日々の計算
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <OrderedList>
                            <ListItem mb={'6px'}>
                                <Link to={`/`}>かけ算ノック</Link>
                            </ListItem>
                            <ListItem>応用</ListItem>
                        </OrderedList>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                計算のテクニック
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <OrderedList>
                            <ListItem mb={'6px'}>
                                <Link to={`/technique/center`}>
                                    まん中の数をさがせ！
                                </Link>
                            </ListItem>
                            <ListItem mb={'6px'}>5で終わる数の計算</ListItem>
                            <ListItem>応用</ListItem>
                        </OrderedList>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                数感をつける遊び
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {' '}
                        <Link to={`/komachi`}>10作りゲーム（こまち算）</Link>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </>
    );
});
